<template>
  <router-view :key="$route.fullPath"/>
</template>

<script>
import isMobile from "@/utils/isMobile";
import isLandScape from "@/utils/isLandscape";

export default {
  name: "App",
  beforeMount: function () {
    this.$store.commit('device', {
      isMobile: isMobile(),
      isLandscape: isLandScape()
    });
    window.onresize = (() => {this.$store.commit('device', {isLandscape: isLandScape()})});
  },
  mounted: function () {
    const loaderContainer = document.getElementById('autosync-visualizer-app-loader');
    loaderContainer.classList.add('d-none');
    loaderContainer.classList.remove('d-flex');
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";

#autosync-visualizer-app {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0d141b;
  width: 100%;
  height: 100%;
  // max-width: $max-width;
}
</style>
