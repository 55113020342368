import validateFiles from '@/utils/validateFiles';
import axios from "axios";

export default async function handleClientConfig (to, store){
    return await axios.get(`${process.env.VUE_APP_ROOT_API}/keys/config?key=${to.query.key}`)
    .then( response =>  {
        const configuration = response.data;

        if (!configuration) return false;

        if (configuration.Visualizer.BrandColor) {
            store.commit('clientSettings', { brandColor: configuration.Visualizer.BrandColor });
        }

        if (validateFiles('image', configuration.Visualizer.BrandLogo)) {
            store.commit('clientSettings', { logo: configuration.Visualizer.BrandLogo });
        }

        if (validateFiles('image', configuration.Visualizer.VehicleLogo)) {
            store.commit('clientSettings', { vehicleImage: configuration.Visualizer.VehicleLogo });
        }

        if (validateFiles('image', configuration.Visualizer.ProductLogo)) {
            store.commit('clientSettings', { productImage: configuration.Visualizer.ProductLogo });
        }

        if (validateFiles('image', configuration.Visualizer.BgImg)) {
            store.commit('clientSettings', { bgImg: configuration.Visualizer.BgImg });
        }

        if (configuration.Visualizer.BgImgOpacity) {
            store.commit('clientSettings', { bgImgOpacity: configuration.Visualizer.BgImgOpacity });
        }

        if (configuration.Visualizer.VehicleThumbnails) {
            store.commit('clientSettings', { vehicleThumbnails: configuration.Visualizer.VehicleThumbnails });
        }

        if (configuration.Locations) {
            store.commit('clientSettings', { locations: configuration.Locations });
        }

        if (configuration.BrandIds) {
            store.commit('clientSettings', { brands: configuration.BrandIds.length });
        }

        if (configuration.HomeCenter === false || configuration.HomeCenter === true) {
            store.commit('clientSettings', { home: { center: configuration.HomeCenter } });
        }

        if (response) {
            store.commit('authorize', {
                status: true,
                key: to.query.key
            });

            return true;
        }
    })
    .catch(() => false);
}
