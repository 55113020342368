import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  state: {
    device: {
      isMobile: false,
      isLandscape: false
    },
    clientSettings: {
      logo: null,
      vehicleImage: null,
      productImage: null,
      bgImg: null,
      brandColor: null,
      vehicleThumbnails: null,
      bgImgOpacity: null,
      brands: null,
      locations: null,
      locationsCoordinates: null,
      home: { center: false }
    },
    authorization: {
      apiKey: null,
      authorized: false,
      timeStart: null
    },
    alert: {
      message: null,
      type: null
    },
    vehicleBuilding: {
      make: null,
      model: null,
      submodel: null,
      year: null,
      wheel: {
        StyleId: null,
        Brand: null
      },
      wheelSelectedWheelVisualizer: false,
      wheelBrand: null,
      wheelPage: null
    },
    vehiclesMakes: {},
    vehiclesGarage: [],
    wheelsBrands: {},
    wheelsBrandsUrlBase: null,
    wheelsFilters: {
      Brands: null,
      Diameters: null,
      ShortColors: null,
      ShortFinishes: null,
      Applications: null,
      Segments: null
    },
    wheelFavorites: {}
  },
  getters: {
    device: state => {
      return state.device;
    },
    clientSettings: state => {
      return state.clientSettings;
    },
    authenticated: state => {
      return state.authorization.authorized;
    },
    key: state => {
      return state.authorization.apiKey;
    },
    authenticationTime: state => {
      return state.authorization.timeStart;
    },
    alert: state => {
      return state.alert;
    },
    vehicleBuilding: state => {
      return state.vehicleBuilding;
    },
    vehiclesMakes: state => {
      return state.vehiclesMakes;
    },
    vehiclesGarage: state => {
      return state.vehiclesGarage;
    },
    wheelsBrands: state => {
      return state.wheelsBrands;
    },
    wheelsBrandsUrlBase: state => {
      return state.wheelsBrandsUrlBase;
    },
    wheelsFilters: state => {
      return state.wheelsFilters;
    },
    wheelFavorites: state => {
      return state.wheelFavorites;
    }
  },
  mutations: {
    device: function (state, payload) {
      state.device = {
        ...state.device,
        ...payload
      }
    },
    clientSettings: function (state, payload) {
      state.clientSettings = {
        ...state.clientSettings,
        ...payload
      };
    },
    authorize: function (state, payload) {
      if (payload.status === true) {
        state.authorization.apiKey = payload.key
        state.authorization.authorized = true;
        state.authorization.timeStart = new Date
      } else {
        state.authorization.apiKey = null;
        state.authorization.authorized = false;
        state.authorization.timeStart = null;
      }
    },
    alert: function (state, payload) {
      state.alert = {
        ...state.alert,
        ...payload
      }
    },
    vehicleBuilding: function (state, payload) {
      state.vehicleBuilding = {
        ...state.vehicleBuilding,
        ...payload
      }
    },
    vehiclesMakes: function (state, payload) {
      state.vehiclesMakes = payload;
    },
    vehiclesGarage: function (state, payload) {
      state.vehiclesGarage = payload;
    },
    wheelsBrands: function (state, payload) {
      state.wheelsBrands = payload;
    },
    wheelsBrandsUrlBase: function (state, payload) {
      state.wheelsBrandsUrlBase = payload;
    },
    wheelsFilters: function (state, payload) {
      if (payload.action === 'clear') {
        state.wheelsFilters = {
          Brands: null,
          Diameters: null,
          ShortColors: null,
          ShortFinishes: null,
          Applications: null,
          Segments: null
        };
      } else {
        state.wheelsFilters[payload.key] = payload.val;
      }
    },
    wheelFavorites: function (state, payload) {
      state.wheelFavorites = payload;
    },
    resetVehicleBuilding: function (state) {
      state.vehicleBuilding = {
        make: null,
        model: null,
        submodel: null,
        year: null,
        wheel: {
          StyleId: null,
          Brand: null
        },
        wheelSelectedWheelVisualizer: false,
        wheelBrand: null,
        wheelPage: null
      };
    },
    resetStore: function (state) {
      state.clientSettings = {logo: null, vehicleImage: null, productImage: null, bgImg:null, brandColor: null, vehicleThumbnails: null, bgImgOpacity: null, brands:null, locations: null, locationsCoordinates: null, home: { center: false }};
      state.authorization = {apiKey: null, authorized: false, timeStart: null};
      state.vehicleBuilding = {
        make: null, model: null, submodel: null,
        year: null, wheel: {StyleId: null, Brand: null},
        wheelSelectedWheelVisualizer: false,
        wheelBrand: null, wheelPage: null
      };
      state.vehiclesMakes = {};
      state.vehiclesGarage = [];
      state.wheelsBrands = {};
      state.wheelsBrandsUrlBase = null;
      state.wheelsFilters = {Brands: null, Diameters: null, ShortColors: null, ShortFinishes: null, Applications: null, Segments: null};
      state.wheelFavorites = {};
    }
  },
  actions: {},
  modules: {},
});
