export default function validateFiles (type, file){
    const extensions = {
        image: ['png', 'jpg', 'jpeg', 'svg', 'webp']
    }

    if (type in extensions) {
        const descompose = file ? file.split('.'):[];
        const descomposeLength = descompose.length - 1;
        const extension = descompose[descomposeLength] || null;
        
        if (extension) {
            if (extensions[type].includes(extension)) {
                return true;
            } else {
                // eslint-disable-next-line
                console.error(`This extension '${extension}' is not valid for this type of files '${type}'`);
            }
        }
    } else {
        // eslint-disable-next-line
        console.error(`File  type '${type}' is not under our validation params`);
    }

    return false;
}
