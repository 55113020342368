import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import store from '../store';
import vehicleBuilder from './middlewares/vehicleBuilder';
import authentication from './middlewares/authentication';

const foreignOrigin = process.env.VUE_APP_ROOT_URL !== window.location.origin;

const routes = [
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "not-found" */ "../views/errors/NotFound.vue"),
  },
  {
    path: "/400",
    name: "BadRequest",
    component: () => import(/* webpackChunkName: "bad-request" */ "../views/errors/BadRequest.vue"),
  },
  {
    path: "/403",
    name: "Forbidden",
    component: () => import(/* webpackChunkName: "forbidden" */ "../views/errors/Forbidden.vue"),
  },
  {
    path: "/500",
    name: "ServerError",
    component: () => import(/* webpackChunkName: "server-error" */ "../views/errors/ServerError.vue"),
  },
  {
    path: "/",
    name: "Index",
    meta: { requiresAuth: true }
  },
  {
    path: "/home",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/home/Home.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/vehicles",
    name: "Vehicles",
    component: () => import(/* webpackChunkName: "vehicles" */ "../views/vehicles/Vehicles.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/vehicles/make/:makeId",
    name: "VehiclesMake",
    component: () => import(/* webpackChunkName: "vehicles-make" */ "../views/vehicles/VehiclesMake.vue"),
    meta: {requiresAuth: true}
  },
  {
    path: "/wheels",
    name: "Wheels",
    component: () => import(/* webpackChunkName: "wheels" */ "../views/wheels/Wheels.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/builder",
    name: "Builder",
    component: () => import(/* webpackChunkName: "builder" */ "../views/builder/Builder.vue"),
    meta: {
      requiresAuth: true,
      allowDirectUrl: true
    }
  },
  {
    path: "/builder-accessories",
    name: "BuilderAccessories",
    component: () => import(/* webpackChunkName: "builder-accessories" */ "../views/builder/BuilderAccessories.vue"),
    meta: {
      requiresAuth: true,
      allowDirectUrl: true
    }
  }
];

const router = createRouter({
  mode: 'history',
  history: foreignOrigin ? createWebHashHistory(process.env.BASE_URL):createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  if (to.name === 'Forbidden') {
    delete to.query.key;
    return;
  }

  if(process.env.VUE_APP_DEVMODE === 'true') {
    // Dev mode
    to.query.key = process.env.VUE_APP_DEVKEY;
  } else if (!('key' in to.query) && store.getters.authenticated) {
    // Embed Key automatically
    to.query.key = store.getters.key;
  }

  // Handle url writing when visualizer in embeded on a page
  if (foreignOrigin && to.path.slice(-2) === '#/') {
    const fullPathTo = to.fullPath.replace(to.path, `${to.path.slice(0, -2)}`);
    return {path: fullPathTo};
  }

  // Handle url writing when visualizer in embeded on a page
  if ('path' in to.query) {
    const URL = decodeURIComponent(to.query.path).split('?');
    const URLPath = URL[0] || '';
    const URLQuey = URL[1] || '';

    let query = {};
    URLQuey.split('&').forEach(param => {
      const parts = param.split('=');
      if (parts[0]) {
        query[parts[0]] = decodeURIComponent(parts[1] || '');
      }
    });

    return {path: URLPath, query: query};
  }

  // Allowing key handle as path
  if (!foreignOrigin && to.name === 'NotFound') {
    to.query.key = to.path.slice(1);
  }

  // Handle the building vehicle information
  vehicleBuilder(to, from, store);

  // Middlewares for routes with flags on meta and routes with key in URL
  if ((to.meta.requiresAuth || to.name === 'NotFound') || 'key' in to.query) {
    // const path = authentication(to, from, store);
    const root = document.documentElement;
    const brandColor = store.getters.clientSettings.brandColor;
    root.style.setProperty('--client-color', brandColor ? `${brandColor}`:process.env.VUE_APP_DEFAULT_BRAND_COLOR);

    return authentication(to, from, store);
  }
});

export default router;
