export default function vehicleBuilder (to, from, store){
    if (to.name === 'VehiclesMake') {
        const currentMake = store.getters.vehicleBuilding.make;
        if (currentMake != to.params.makeId) {
            store.commit('vehicleBuilding', {
                make: to.params.makeId,
                model: null,
                submodel: null,
                year: null
            });
        }
    }
    if (to.name === 'Builder') {
        store.commit('vehicleBuilding', {
            make: to.query.vehicle_make,
            model: to.query.vehicle_model,
            submodel: to.query.vehicle_submodel,
            year: to.query.vehicle_year
        });
    }
}
