import verifyAuth from '../utils/verifyAuth';
import handleClientConfig from '../utils/handleClientConfig';

export default async function (to, from, store) {
    let key = to.query.key || '';
    const previousState = store.getters.authenticated;
    const currentTime = new Date;
    const authenticationTime = new Date(store.getters.authenticationTime);
    const timeBetweenVerifications = 5 * 60 * 1000 //5 Minutes;
    const timeBetweenVerificationOnHome = 5 * 1000 //5 Seg;
    const timeBetweenReset = 60 * 60 * 1000 //1 Hour;
    
    if (!verifyAuth(store) || !key || key !== store.getters.key || (currentTime - authenticationTime) > timeBetweenVerifications || ((currentTime - authenticationTime) > timeBetweenVerificationOnHome && to.name === 'Home')) {
        if (key !== store.getters.key || (currentTime - authenticationTime) > timeBetweenReset) {
            store.commit('resetStore');
        }

        return handleClientConfig(to, store).then( async response => {
            if (response) {
                if (to.name === 'Index' || (!from.name && !to.meta.allowDirectUrl) || to.name === 'NotFound') {
                    return {path: '/home'};
                }

                if (to.meta.onDirectUrlRedirectTo && to.meta.parentUrl && !previousState) {
                    if (from.path !== to.meta.parentUrl) {
                        return {path: to.meta.onDirectUrlRedirectTo};
                    }
                }
            } else {
                throw new Error('Authentication failed');
            }
        }).catch( () => {
            store.commit('resetStore');
            return {path: '/403'}
        });
    } else {
        if (
            to.name === 'Index' ||
            (to.name === 'NotFound' && key)
        ) {
            return {path: '/home'};
        }
    }
}
